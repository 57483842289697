<!--
 * @Author: wy 15660501845@163.com
 * @Date: 2024-08-28 09:22:38
 * @LastEditors: wy 15660501845@163.com
 * @LastEditTime: 2024-08-28 10:05:12
 * @FilePath: /welfare-mall/welfare-mall-website/src/views/pages/golden-jubilee/components/footer.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="tw-goldenJubilee-footer">
    <div class="tw-footer-bottom-contonet">
      <div class="desc">Copyright @ 江苏金禧智能卡管理有限公司</div>
      <!-- <div class="desc">江苏金禧智能卡管理有限公司 客服电话：400-8676-908</div> -->
      <div class="desc">备案号 <a style="color: #1954ff" href="https://beian.miit.gov.cn">苏ICP备19006479号-1</a></div>
      <div class="img-box">
        <a target="_blank" rel="nofollow" href="https://online.unionpay.com/?tp=1.0.20.0.22.KbdwQJG">
          <img src="https://xb-dmp-pro.oss-cn-shanghai.aliyuncs.com/upload/20240827/6dfcde17e551ab1ddf4cfd2f588de6c2.png" alt="" />
        </a>
        <a target="_blank" rel="nofollow" href="https://beian.mps.gov.cn/#/">
          <img class="third" src="https://xb-dmp-pro.oss-cn-shanghai.aliyuncs.com/upload/20240827/6d3e13f209beff3e6fd51f6a81841d23.png" alt="" />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {

    }
  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>
 .tw-goldenJubilee-footer {
    width: 100%;
    height: 242px;
    background: #F3F3F3;
    .tw-footer-bottom-contonet {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      height: 242px;
      .desc {
        font-size: 16px;
        color: #333;
        margin-bottom: 20px;
        b {
          display: inline-block;
          width: 38px;
        }
      }
      .img-box {
        img {
          height: 32px;
          margin-right: 30px;
        }
      }
    }
  }
</style>
