/*
 * @Author: wy 15660501845@163.com
 * @Date: 2024-08-27 09:29:50
 * @LastEditors: wy 15660501845@163.com
 * @LastEditTime: 2024-08-28 17:37:38
 * @FilePath: /welfare-mall/welfare-mall-website-jxk/src/router/index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'index',
    redirect: '/index',
    component: () => import('@/views/pages/index'),
  },
  {
    path: '/index',
    name: 'index',
    component: () => import('@/views/pages/index'),
  },
  {
    path: '/article',
    name: 'article',
    component: () => import('@/views/pages/article'),
  },
];

const router = new VueRouter({
  routes,
});

export default router;
