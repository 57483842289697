import Vue from 'vue';
import ElementUI from 'element-ui';
import './plugins/element';
import 'element-ui/lib/theme-chalk/index.css';
import './utils/amfe-flexible/index';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';

Vue.use(ElementUI);
Vue.config.productionTip = false;

export const _this = new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
