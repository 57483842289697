const app = {
  state: {
    ua: sessionStorage.getItem('uaType') || '',
    network: false,
    scanInside: sessionStorage.getItem('scanInside') ? JSON.parse(sessionStorage.getItem('scanInside')) : false,
    routePath: '/',
  },
  getters: {
    // getActive:state=>state.footbar.opened
    uaType: (state) => state.ua,
    network: (state) => state.network,
    scanInside: (state) => state.scanInside,
    routePath: (state) => state.routePath,
  },
  mutations: {
    setroutePath(state, args) {
      state.routePath = args;
    },
    setUA(state, type) {
      sessionStorage.setItem('uaType', type);
    },
    network(state, args) {
      state.network = args;
    },
    scaninside(state, args) {
      state.scanInside = args;
      sessionStorage.setItem('scanInside', JSON.stringify(args));
    },
  },
  actions: {
    getUA({ commit }) {
      const ua = window.navigator.userAgent.toLowerCase();

      // if (ua.includes('micromessenger')){
      //   commit('setUA','wechat')
      // }
      commit('setUA', 'wechat');
    },
    setNetworlk({ commit, state }, args) {
      commit('network', args);
    },
    setRoutePath({ commit, state }, args) {
      commit('setroutePath', args);
    },
    setScanInside({ commit, state }, args) {
      commit('scaninside', args);
    },
  },
};

export default app;
