<!--
 * @Author: wy 15660501845@163.com
 * @Date: 2024-01-05 10:05:31
 * @LastEditors: wy 15660501845@163.com
 * @LastEditTime: 2024-08-28 17:33:22
 * @FilePath: /welfare-mall/welfare-mall-website-jxk/src/App.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div id="app">
      <div class="flex-container">
        <div ref="scrollbox" class="flex-item scrollbox">
          <router-view></router-view>
          <Footer></Footer>
        </div>
      </div>
  </div>
</template>

<script>
import Footer from '@/components/footer.vue';

export default {
  name: 'app',
  components: {
    Footer,
  },
  data() {
    return {
      currentName:null
    }
  },
  mounted() {
  },
  methods: {
  },
};

</script>

<style lang="scss">
  #app {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  html{
    height: 100%;
    background-color: #f3f3f3;
  }
  body{
    height: 100%;
    background-color: #fff;
  }
  .el-container{
    flex-direction: column;
  }
  .tw-header-container{
    // height: 80px;
    box-sizing: border-box;
    -ms-flex-negative: 0;
    flex-shrink: 0;
  }
  // @import '@/scss/main.scss';
  div:focus {
    outline: none;
  }

  ::-webkit-scrollbar {
    display: none;
  }

  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  .el-main {
    padding: 0;
  }

</style>
