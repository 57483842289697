(function flexible(window, document) {
  const docEl = document.documentElement;
  const dpr = window.devicePixelRatio || 1;

  // adjust body font size
  function setBodyFontSize() {
    if (document.body) {
      document.body.style.fontSize = `${12 * dpr}px`;
    } else {
      document.addEventListener('DOMContentLoaded', setBodyFontSize);
    }
  }
  setBodyFontSize();

  // set 1rem = viewWidth / 10
  function setRemUnit() {
    console.log('docEl.clientWidth', docEl.clientWidth);
    if (docEl.clientWidth > 1920) {
      console.log('1', docEl.clientWidth);
      var rem = 1920 / 10;
      docEl.style.fontSize = `${rem}px`;
    }else if ( docEl.clientWidth > 1600 && docEl.clientWidth <= 1920) {
      console.log('2', docEl.clientWidth);
      var rem = 1600 / 10;
      docEl.style.fontSize = `${rem}px`;
    }else if ( docEl.clientWidth >= 768 && docEl.clientWidth <= 1600) {
      console.log('3', docEl.clientWidth);
      var rem = docEl.clientWidth / 10;
      docEl.style.fontSize = `${rem}px`;
    } else {
      console.log('4', docEl.clientWidth);
      var rem = 1010 / 10;
      docEl.style.fontSize = `${rem}px`;
    }
  }

  setRemUnit();

  // reset rem unit on page resize
  window.addEventListener('resize', setRemUnit);
  window.addEventListener('pageshow', (e) => {
    if (e.persisted) {
      setRemUnit();
    }
  });

  // detect 0.5px supports
  if (dpr >= 2) {
    const fakeBody = document.createElement('body');
    const testElement = document.createElement('div');
    testElement.style.border = '.5px solid transparent';
    fakeBody.appendChild(testElement);
    docEl.appendChild(fakeBody);
    if (testElement.offsetHeight === 1) {
      docEl.classList.add('hairlines');
    }
    docEl.removeChild(fakeBody);
  }
}(window, document));
